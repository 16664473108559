import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Map from "../images/map.svg"
import CookieConsent from "react-cookie-consent";
import Header from "./header"

const Layout = ({ children, navigationItems, langKey }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="d-flex flex-column min-vh-100">
        <Header
          langKey={langKey}
          navigationItems={navigationItems}
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
      <div className="container mb-5">
        <main className="">{children}</main>
      </div>
      <footer id="contact" className="mt-auto">
        <div className="container py-5 text-light">
          <div className="row">
            <div className="col-md-4">
              <p className="fs-5 text-uppercase text-primary fw-bold letter-spacing">
                Contact
              </p>
              <p>
                Tel: 32 (0)56 35 52 64<br></br>
                E-mail: <a href="mailto:info@giesla.be">info@giesla.be</a><br></br>
                <br></br>
                BTW nr. 0432.140.641<br></br>
                H.R. Kortrijk 113654<br></br>
                <a href="Algemene voorwaarden.pdf" target="_blank">Algemene voorwaarden</a>
              </p>
            </div>
            <div className="col-md-4">
              <p className="fs-5 text-uppercase text-primary fw-bold letter-spacing">
                Address
              </p>
              <p>
                Giesla b.v.<br></br>
                Pijplap 132 bus 2<br></br>
                8560 WEVELGEM-GULLEGEM<br></br>
                Belgium<br></br>
              </p>
            </div>
            <div className="col-md-4">
              <img alt="Giesla on a map" src={Map}/>
            </div>
          </div>

        </div>
      </footer>
      <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
This site uses cookies ...
</CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
