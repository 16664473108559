import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
// import { useTranslation } from "gatsby-plugin-react-i18next"
import Slider from "react-slick"

const Page = ({ data, pageContext }) => {
  // const { t } = useTranslation()
  const pageData = data.cms.page[0]
  const navigationItems = data.cms.navigation
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const renderSlides = () => {
    return pageData.images.map((image) => <div className="slide" key={image.id} >
      <div className="slide-content" style={{backgroundImage: 'url("https://cms.giesla.be/'+image.path+'")'}}></div>
    </div>);
  }
  const renderProducts = () => {
    const products = pageData.product.map((product) => {
      return (<div className="col-md-4">
        <div className="card mb-5">
        <img src={'https://cms.giesla.be/'+product.images[0].path} class="card-img-top" alt={product.title}/> 
          <div className="card-body p-4">
            <h2 className="fw-bold h3">{product.title}</h2>
            <div className="py-3" dangerouslySetInnerHTML={{
            __html: product.body
          }}></div>
          </div>
        </div>
      </div>)
    })
    return <div className="products my-5 row">{products}</div>
  }
  return (
    <Layout langKey={pageContext.lang} navigationItems={navigationItems}>
      <SEO
        description={pageData.seo_description}
        title={pageData.seo_title}
        keywords={pageData.seo_keywords}
      />
      <div style={{minHeight: '500px'}} className="row g-0 my-5 d-flex flex-row-reverse border-left">
        <div className={pageData.layout === 'home' ? 'col-md-8' : 'col-md-6'}>
          <Slider className="slides" {...sliderSettings}>
            {renderSlides()}
          </Slider>
        </div>

        <div className={pageData.layout === 'home' ? 'col-md-4 p-5 bg-white' : 'col-md-6 p-5 bg-white'}>
          <h1>{pageData.title}</h1>
          <div dangerouslySetInnerHTML={{
            __html: pageData.body
          }}></div>
        </div>
      </div>
      {(pageData.product && pageData.product.length > 0) ? renderProducts() : ''}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($lang: String, $id: Int, $language: String) {
    cms {
      page: pages(lang: $lang, id: $id) {
        id
        layout
        seo_title
        seo_description
        seo_keywords
        title
        slug
        body
        images {
          id
          title
          path
        }
        product {
          id
          title
          body
          images {
            id
            title
            path
          }
        }
      }
      navigation: pages(lang: $lang) {
        id
        seo_title
        slug
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
