import { Link } from "gatsby"
import React from "react"
import logo from "../images/logo-giesla.png"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Header = ({ navigationItems, pageContext, langKey }) => {
  const { t } = useTranslation()

  const renderNavigationItems = () => {
    let langPrefix = (langKey === 'en') ? 'en/' : ''
    return navigationItems.map(item => {
      let slug = (item.slug === 'home') ? '' : item.slug;

      return (
        <li className="nav-item mx-3" key={item.id}>
          <Link
            className="nav-link"
            activeClassName="active"
            to={"/" + langPrefix + slug}
          >
            {item.seo_title}
          </Link>
        </li>
      )
    })
  }
  return (
    <>
      <div className="topbar d-flex flex-column flex-md-row justify-content-between bg-light">
        <span className="p-2 text-primary">Kortrijk Belgium</span>
        <span className="p-2 text-primary">{t("topbartitle")}</span>
        <ul className="nav lang-switch">
          <li className="nav-item mx-3">
            <Link
              className={"nav-link " + (langKey === "nl" ? "active" : "")}
              to="/"
            >
              NL
            </Link>
          </li>
          <li className="nav-item mx-3">
            <Link
              className={"nav-link " + (langKey === "en" ? "active" : "")}
              to="/en/"
            >
              EN
            </Link>
          </li>
        </ul>
      </div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container d-flex justify-content-between">
            <Link className="navbar-brand" to="/">
              <img alt="Logo Giesla" width="320" src={logo}></img>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarContent"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {renderNavigationItems()}
                <li className="nav-item mx-3">
                  <Link className="nav-link" to={"#contact"}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
